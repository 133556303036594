<template>
  <card :title="$t('church.info')" :link="{to:{name: 'churches.show', params: {id: church_id, name: slugify(form.name)}}, text: $t('View')}">
    <form @submit.prevent="update" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('church.updated')" />

      <church-form :form="form" :edit="true"></church-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">
            {{ $t('update') }}
          </v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
  import Form from 'vform'
  import ChurchForm from '../../accounts/church/form'
  import { mapGetters } from 'vuex'

  export default {
    scrollToTop: false,
    components: {
      'church-form': ChurchForm
    },
    data: () => ({
      church_id: null,
      form: new Form({
        name: '',
        phone: '',
        email: '',
        website: '',
        facebook: '',
        twitter: '',
        instagram: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        logoUrl: '',
        logo: null,
      })
    }),
    async created() {
      // See if we have the church
      let church = this.churchById(this.$route.params.church_id);

      // If we don't have the church fetch all churches
      // TODO: Only fetch this church
      if (!church) {
        await this.$store.dispatch('admin/fetchChurches');        
        church = this.churchById(this.$route.params.church_id);
      } 

      // Load church data into our form model
      this.refresh(church);
    },
    computed: mapGetters({
      churches: 'admin/churches',
      churchById: 'admin/churchById'
    }),
    methods: {
      refresh(church) {
        this.church_id = church.id;
        this.form.keys().forEach(key => {
          this.form[key] = church[key];
        });
      },
      async update() {
        let vm = this;
        this.$store.dispatch('admin/updateChurch', {form: this.form, params: {id: this.church_id}}).then(() => {
          // Update the form
          let church = vm.churchById(vm.$route.params.church_id);
          vm.refresh(church);
        });
      }
    }
  }
</script>
